var $payment = {};

$payment.init = function(){
    $('#payment-form').submit(function(){
        $('#submit-button').attr('disabled', 'disabled');
        $.ajax({
            url: $('#payment-form').attr('action'),
            type: 'post',
            data: $('#payment-form').serialize(),
            dataType: 'json',
            success: function(response){
                $('#submit-button').removeAttr('disabled');
                if(response.status == 'OK'){
                    if (typeof response.data.payment != 'undefined') {
                        $('#idram-amount').val(response.data.payment.amount);
                        $('#idram-billno').val(response.data.payment.orderNumber);
                        $('#decsription').val(response.data.payment.decsription);
                        $('#payment-hash').val(response.data.payment.hash);
                        $('#idram-form').submit();
                    } else {
                        window.location.href = response.data.redirect_uri;
                    }
                } else if(response.status == 'INVALID_DATA'){
                    $payment.showErrors(response.errors);
                } else if(response.status == 'ERROR'){
                    alert(response.message);
                }
            }
        });
        return false;
    });

    $("#amount").focus(function(){
        $('.money-box .money-input').attr('disabled', 'disabled').prop('checked', false);
        $('.money-box .money-label').addClass('disabled');
    });

    $(".money-box .money-item").click(function(){
        $('.money-box .money-input').removeAttr('disabled');
        $('.money-box .money-label').removeClass('disabled');
    });

    $('.currency-input').change(function(){
        $('.money-box .row').hide();
        $('.stamps-' + $(this).data('code')).fadeIn();
        $('.other-method-input-box').removeClass('amd rur usd eur').addClass($(this).data('class-name'));
    });
};

$payment.showErrors = function(errors){
    $('#payment-form .form-error').removeClass('form-error');
    $('#payment-form .form-error-message').hide();
    for(var i in errors){
        $('#' + i).addClass('form-error');
        $('#' + i).parent().find('.form-error-message').html(errors[i]).fadeIn();
    }

    $('#payment-form .form-error').focus(function(){
        $(this).parent().find('.form-error-message').fadeOut();
    });
};

$(document).ready(function(){
    $payment.init();

    $("[name='data[method]']").change(function(){
        if($(this).val() == 'paypal') {
            if($(".currency-input:checked").val() == '051') {
                $("#rur").click();
            }
            $("#amd").closest('.currency-label-box').css('display', 'none');
            // $("#monthly").prop('checked', false);
            // $("#monthly").closest('.payment-choose-inner-box').hide();
        } else if($(this).val() == 'idram') {
            if($(".currency-input:checked").val() != '051') {
                $("#amd").click();
            }
            $("#rur").closest('.currency-label-box').css('display', 'none');
            $("#usd").closest('.currency-label-box').css('display', 'none');
            $("#euro").closest('.currency-label-box').css('display', 'none');
        } else {
            // $("#monthly").closest('.payment-choose-inner-box').show();
            $("#amd").closest('.currency-label-box').css('display', 'inline-block');
            $("#rur").closest('.currency-label-box').css('display', 'inline-block');
            $("#usd").closest('.currency-label-box').css('display', 'inline-block');
            $("#euro").closest('.currency-label-box').css('display', 'inline-block');
        }
    })
});
