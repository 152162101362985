'use strict';

function Main(){

}

Main.prototype.dropDown = function ( dropper, dropBox ) {

    if ( !dropper.hasClass('active') ) {

        dropBox.find('.drop-down-title.active').removeClass('active');
        dropBox.find('.list:visible').stop().slideUp('fast', function(){
            dropBox.removeAttr('style');
        });

        dropper.addClass('active');
        dropper.next().stop().slideDown('fast');

    } else {

        dropper.removeClass('active');
        dropper.next().stop().slideUp('fast', function(){
            dropper.next().removeAttr('style');
        });

    }

    $(document).on('click', function(event){

        var $clicked = $(event.target);

        if ( !$clicked.parents().is('.drop-down') || $clicked.is('.ccy') ) {

            dropper.removeClass('active');
            dropper.next().stop().slideUp('fast');
            //$(document).off('click');

        }

    });

};

function setEqualHeightEachRowElems() {

    var elems = $('.donation-list__item, .equal-item'),
        max = 0;

    if ( !elems.length ) {
        return;
    }

    elems.removeAttr('style');

    elems.each(function (i, e) {
        var $e = $(e);

        if ( $e.height() > max ) {
            max = $e.height();
        }

    });

    elems.height(max);

}

setEqualHeightEachRowElems();

setTimeout(setEqualHeightEachRowElems, 100);

$(window).resize(function(){
    setTimeout(setEqualHeightEachRowElems, 250);
});

Main.prototype.datepickerSetLocalization = function( lngCode ){

    if ( lngCode == 'hy' ) {

        $.datepicker.regional['hy'] = {
            closeText: 'Փակել',
            prevText: '&#x3c;Նախ.',
            nextText: 'Հաջ.&#x3e;',
            currentText: 'Այսօր',
            monthNames: ['Հունվար', 'Փետրվար', 'Մարտ', 'Ապրիլ', 'Մայիս', 'Հունիս', 'Հուլիս', 'Օգոստոս', 'Սեպտեմբեր', 'Հոկտեմբեր', 'Նոյեմբեր', 'Դեկտեմբեր'],
            monthNamesShort: ['Հունվ', 'Փետր', 'Մարտ', 'Ապր', 'Մայիս', 'Հունիս', 'Հուլ', 'Օգս', 'Սեպ', 'Հոկ', 'Նոյ', 'Դեկ'],
            dayNames: ['կիրակի', 'եկուշաբթի', 'երեքշաբթի', 'չորեքշաբթի', 'հինգշաբթի', 'ուրբաթ', 'շաբաթ'],
            dayNamesShort: ['կիր', 'երկ', 'երք', 'չրք', 'հնգ', 'ուրբ', 'շբթ'],
            dayNamesMin: ['կիր', 'երկ', 'երք', 'չրք', 'հնգ', 'ուրբ', 'շբթ'],
            weekHeader: 'ՇԲՏ',
            dateFormat: 'dd.mm.yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };

    } else if ( lngCode == 'ru' ) {

        $.datepicker.regional['ru'] = {
            closeText: 'Закрыть',
            prevText: '&#x3c;Пред',
            nextText: 'След&#x3e;',
            currentText: 'Сегодня',
            monthNames: ['Январь','Февраль','Март','Апрель','Май','Июнь',
                'Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
            monthNamesShort: ['Янв','Фев','Мар','Апр','Май','Июн',
                'Июл','Авг','Сен','Окт','Ноя','Дек'],
            dayNames: ['воскресенье','понедельник','вторник','среда','четверг','пятница','суббота'],
            dayNamesShort: ['вск','пнд','втр','срд','чтв','птн','сбт'],
            dayNamesMin: ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
            weekHeader: 'Нед',
            dateFormat: 'dd.mm.yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };

    }

    $.datepicker.setDefaults( $.datepicker.regional[ lngCode ] );

};

Main.prototype.menuClose = function( nav ){

    nav.find('.first-level.active').removeClass('active');
    nav.find('.second-level-list:visible').addClass('dn');

};

Main.prototype.toggleDropDownMenu = function( self, that ){

    var nav  = that.parents('nav'),
        list = that.next();

    if ( !that.hasClass('active') ) {
        self.menuClose( nav );
    }

    that.toggleClass('active');
    list.toggleClass('dn');

    $(document).click(function( event ){

        var $clicked = $(event.target);

        if ( !$clicked.parents().is('nav') ) {
            self.menuClose( nav );
        }

    });

    if ( that.offset().left + list.outerWidth() > $(window).width() ) {
        list.addClass('rtl');
    }

};

Main.prototype.pluginCalls = function( self ){

    // Init donation text dots
    $('.donation-table .full-text').each(function(i, elem){
        var $this = $(elem);
        $this.before( $('<div class="three-dots">' + $this.text() + '</div>') );
    });

    // Home fade slider call
    $('main .fade-slider-wrapper').fadeSlider({
        autoPlaySpeed : 3000,
        btnsText      : {
            next      : "",
            prev      : "",
            pause     : "",
            play      : ""
        },
        hoverPause    : true,
        navigation    : true,
        speed         : 1000,
        swipe         : false,
        width         : "100%"
    });

    // Dotdotdot call
    $('.three-dots').dotdotdot();

    $('.three-dots-without-ellipsys').dotdotdot({
        ellipsis	: ''
    });

    // Paging input number validation
    $('.paging-input').on('input keydown', function(){

        var that = $(this);

        if ( /[^0-9]/g.test( that.val() ) ) {
            that.val( that.val().replace(/[^0-9]/, '') );
        }

    });

    // Datepicker from to call

    if ( $('body').hasClass('hy') ) {
        self.datepickerSetLocalization( 'hy' );
    } else if ( $('body').hasClass('ru') ) {
        self.datepickerSetLocalization( 'ru' );
    }

    var from = $('#from'),
        to   = $('#to'),
        datepicker = $(".datepicker"),
        fromMinDate = null,
        fromMinDateVal = to.parent().find('#to-date').val(),
        toMinDate = null,
        toMinDateVal = from.parent().find('#from-date').val();

    if ( fromMinDateVal !== '' ) {
        fromMinDate = new Date( fromMinDateVal );
    }

    if ( toMinDateVal !== '' ) {
        toMinDate = new Date( toMinDateVal );
    }

    from.datepicker({
        showOn      : 'both',
        buttonImage : '/img/ui-icon-calendar.png',
        dateFormat  : 'dd M, yy',
        altFormat: "yy-mm-dd",
        altField: "#from-date",
        minDate: fromMinDate,
        onClose: function( selectedDate ) {
            if( $(this).val() === '' ){
                from.parent().find('#from-date').val('');
            }
        },
        beforeShow:function(textbox, instance){
            from.parent().append( $('#ui-datepicker-div') );
        }
    });

    to.datepicker({
        showOn      : 'both',
        buttonImage : '/img/ui-icon-calendar.png',
        dateFormat  : 'dd M, yy',
        altFormat: "yy-mm-dd",
        altField: "#to-date",
        minDate: toMinDate,
        onClose     : function( selectedDate ) {
            if( $(this).val() === '' ){
                to.parent().find('#to-date').val('');
            }
        },
        beforeShow:function(textbox, instance){
            to.parent().append( $('#ui-datepicker-div') );
        }
    });

    datepicker.datepicker({
        showOn      : 'both',
        buttonImage : '/img/ui-icon-calendar.png',
        dateFormat  : 'dd M, yy',
        altFormat: "yy-mm-dd",
        altField: "#send-date",
        minDate: 0,
        beforeShow:function(textbox, instance){
            datepicker.parent().append( $('#ui-datepicker-div') );
        }
    });

};

Main.prototype.resetBurgerStyles = function( self ){

    if ( (window.innerWidth || screen.availWidth) > 768 ) {

        var body = $('body');

        $('#burger').removeClass('open');
        body.removeClass('lock');
        body.find('nav').removeAttr('style');

    }

};

Main.prototype.burgerToggle = function( burger ){

    var body = $('body');

    burger.toggleClass('open');
    body.find('nav').stop().slideToggle('fast', function(){

        if ( body.find('nav').is(':hidden') ) {
            body.find('nav').removeAttr('style');
        }

    });

    if ( (window.innerWidth || screen.availWidth) < 769 ) {

        body.toggleClass('lock');

        if ( burger.hasClass('open') ) {

            $(document).on('touchmove', function(event){

                if( !$('nav').has($(event.target)).length ) {

                    event.preventDefault();
                }

            });

        } else {

            $(document).off('touchmove');

        }

    }

};

Main.prototype.clickEvents = function( self ){

    // Menu drop down fn
    $('nav .with-drop-down').click(function(){
        self.toggleDropDownMenu( self, $(this) );
    });

    // Drop down list
    $('.drop-down-title').click(function(){
        self.dropDown( $(this), $('.drop-down') );
    });

    // Call member popup
    $('main .member-link').click(function(){

        var contentHtml = $(this).closest('.member-top-box, .member-box').find('.popup-info').html();

        $popup.initialize({
            html : contentHtml,
            className        : 'member-popup',
            destroyAnimation : true,
            complete         : function( popupBox ){

                // Do something

            }
        });

        return false;

    });

    // Burger toggle
    $('#burger').click(function(){
        self.burgerToggle( $(this) );
    });

    // See all donation list
    //$('.table-page .see-all').click(function(){
    //
    //    $popup.initialize({
    //        htmlUrl          : $(this).attr('href'),
    //        className        : 'donation-popup',
    //        destroyAnimation : true,
    //        complete         : function( popupBox ){
    //
    //            popupBox.find('.drop-down-title').click(function(){
    //                self.dropDown( $(this), popupBox.find('.drop-down') );
    //            });
    //
    //        }
    //    });
    //    return false;
    //
    //});

    // Trigger "payment choose inner info" box
    $('main .payment-choose-inner-info').click(function(){});

    // Click on slider-box
    $('.slider-box').click(function(){
        if ( (window.innerWidth || screen.availWidth) < 768 ) {
            window.location.href = $(this).find('.slider-title a').attr('href');
        }
    });


    function removeAddedField(formBox) {

        formBox.find(".close__icon").click(function () {
            formBox.slideUp(function () {
                formBox.remove();

                var i=0;
                $(".payment__field").each(function(key,elem){
                    $(elem).attr('data-index', i);
                    $(elem).attr('id', 'payee_email_'+i);
                    i++;
                });

            });
            return false;
        });

    }



    $(".add__text").click(function () {

        var $payment__field = $(".payment__field").last().clone();
        $payment__field.val('');
        $payment__field.removeClass('form-error');
        var index = parseInt($payment__field.attr('data-index')) + 1;
        $payment__field.attr('id', 'payee_email_'+index);
        $payment__field.attr('data-index', index);

        var $htmlInput = $("<div class='form__box pr dn'>");
        $htmlInput.append($payment__field);
        $htmlInput.append('<div class="form-error-message fs14 dn"></div>');
        $htmlInput.append("<a class='close__icon sprite' href='#'></a>");

        $(".email-inner").append($htmlInput);

        $htmlInput.slideDown();

        removeAddedField($htmlInput);

        // $($htmlInput).prependTo($(".email__block")).slideDown();

        return false;
    });

    // Iframe append
    $('.iframe-box').click(function(){

        var self = $(this);

        if ( !self.hasClass('iframe-box--init') ) {
            self.addClass('iframe-box--init');
            self.html('<iframe class="iframe-box__item pa" width="560" height="315" src="' + self.data('url') + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
        }

    });

};

Main.prototype.scroller = function( self, win ){

    var winScrollTop = win.scrollTop(),
        buttonsGroup = $('main .top-buttons-group');

    if ( winScrollTop > $('main').offset().top ) {
        buttonsGroup.addClass('sticky');
    } else {
        buttonsGroup.removeClass('sticky');
    }

};

Main.prototype.windowEvents = function( self ){

    var timer = null;

    // Window resize event
    $(window).resize(function(){
        self.resetBurgerStyles( self );

        timer = setTimeout(function(){
            $('.three-dots').dotdotdot('destroy').dotdotdot();

            $('.three-dots-without-ellipsys').dotdotdot('destroy').dotdotdot({
                ellipsis	: ''
            });
        }, 100);

    });

    self.scroller( self, $(window) );

    // Window scroll event
    $(window).scroll(function(){

        self.scroller( self, $(this) );

    });

    // Window on load event
    $(window).on('load', function(){

        $('main .fade-slider-wrapper').addClass('visible');

    });

};

Main.prototype.hoverEvents = function( self ){

    $('.donation-table-1 .three-dots').parent().hover(function(){
        $(this).find('.three-dots').addClass('dn')
               .next().removeClass('dn');
    }, function(){
        $(this).find('.three-dots').removeClass('dn')
               .next().addClass('dn');
    });

};

Main.prototype.forStaticContent = function(){

    // Wrap table's into scroller block
    $('.static-content table').wrap('<div class="scroller-block"></div>');

};

Main.prototype.changeEvents = function(){

    // Radio change event
    $('.radio-style-input').change(function(){

        var self = $(this);

        $( self.data('hidden') ).hide();
        $( self.data('box') ).fadeIn('fast');

    });

    // Stamp pay change event
    $('#stamp-pay').change(function(){

        var selectedOption = $(this).find('option:selected');

        $( selectedOption.data('hidden') ).hide();
        $( selectedOption.data('box') ).fadeIn('fast');

    });

};

Main.prototype.init = function(){

    var self = this;

    // Click events
    self.clickEvents( self );

    // Plugin calls
    self.pluginCalls( self );

    // Hover events
    self.hoverEvents( self );

    // Window events
    self.windowEvents( self );

    // Static content
    self.forStaticContent( self );

    // Change events
    self.changeEvents( self );

};

var $main = new Main();

$main.init();

$('.field-input-box__clear').click(function(){
    var self = $(this);

    self.parent().find('input').val('');
    self.parent().find('.field-input-box__clear').removeClass('db');
});

$('.field-input-box--with-clear input').on('input', function(){
    var self = $(this);

    self.parent().find('.field-input-box__clear').toggleClass('db', self.val().length > 0);
});
