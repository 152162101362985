var $freewill = {};

$freewill.init = function(){
    $('#freewill-search-form').submit(function(){
        $('#submit-button').attr('disabled', 'disabled');
        if ($('#freewill-search-form').data('redirect') == 'true') {
            return true;
        }
        $.ajax({
            url: $('#freewill-search-form').attr('action'),
            type: 'post',
            data: $('#freewill-search-form').serialize(),
            dataType: 'json',
            success: function(response){
                $('#submit-button').removeAttr('disabled');
                if(response.status == 'OK'){
                    $(".zebra-table-box").empty();
                    $(".zebra-table-box").append(response.data.view);
                    if (typeof response.data.redirect != 'undefined') {
                        $('#freewill-search-form').data('redirect', 'true');
                        $('#freewill-search-form').submit();
                    }
                } else if(response.status == 'INVALID_DATA'){
                    $freewill.showErrors(response.errors);
                } else if(response.status == 'ERROR'){
                    alert(response.message);
                } else if(response.status == 'TOKEN_MISMATCH') {
                    if(response.redirect_to != '') {
                        window.location.href = response.redirect_to;
                    }
                }
            },
            error: function(response){
                alert(response.status + ' ' + response.statusText);
            }
        });
        return false;
    });

    $(".lonely-field").focus(function(){
        $('#freewill-search-form #type-value').val($(this).data('type'));
        $(this).removeClass('disabled').attr('name', $(this).data('name'));
        $('#freewill-search-form .lonely-field').not($(this)).addClass('disabled').removeAttr('name').val('');
        $('.form-error-message').fadeOut();
    });

    $(".lonely-field[value!='']").focus();

};

$freewill.showErrors = function(errors){
    $('#freewill-search-form .form-error').removeClass('form-error');
    $('#freewill-search-form .form-error-message').hide();
    for(var i in errors){
        $('#' + i).addClass('form-error');
        $('#' + i).parent().find('.form-error-message').html(errors[i]).fadeIn();
    }
};

$(document).ready(function(){
    $freewill.init();
});