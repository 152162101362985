var $mandatoryForm = {};

$mandatoryForm.init = function(){
    $('#mandatory-search-form').submit(function(){
        $('#submit-button').attr('disabled', 'disabled');
        if ($('#mandatory-search-form').data('redirect') == 'true') {
            return true;
        }
        $.ajax({
            url: $('#mandatory-search-form').attr('action'),
            type: 'post',
            data: $('#mandatory-search-form').serialize(),
            dataType: 'json',
            success: function(response){
                $('#submit-button').removeAttr('disabled');
                if(response.status == 'OK'){
                    $('.zebra-table-box').html(response.data);
                    if (typeof response.data.redirect != 'undefined') {
                        $('#mandatory-search-form').data('redirect', 'true');
                        $('#mandatory-search-form').submit();
                    }

                } else if(response.status == 'INVALID_DATA'){
                    $mandatoryForm.showErrors(response.errors);
                } else if(response.status == 'ERROR'){
                    alert(response.message);
                } else if(response.status == 'TOKEN_MISMATCH') {
                    if(response.redirect_to != '') {
                        window.location.href = response.redirect_to;
                    }
                }

                $mandatoryForm.initWidgets();
            },
            error: function(response){
                alert(response.status + ' ' + response.statusText);
            }
        });
        return false;
    });

    $(".lonely-field").focus(function(){
        $('#mandatory-search-form #type-value').val($(this).data('type'));
        $(this).removeClass('disabled').attr('name', $(this).data('name'));
        $('#mandatory-search-form .lonely-field').not($(this)).addClass('disabled').removeAttr('name').val('');
        $('.form-error-message').fadeOut();
    });

    $(".currency-input").change(function(){
        $(".sum-list > [class*=sum-]").hide();
        $(".sum-list .sum-" + $(this).val()).show();
    });

    $mandatoryForm.initWidgets();
};

$mandatoryForm.initWidgets = function(){
    $('.table-page .see-all').click(function(){

        $popup.initialize({
            html: $('.all-payments-list-popup').html(),
            className        : 'donation-popup',
            destroyAnimation : true,
            complete         : function( popupBox ){

                popupBox.find('.drop-down-title').click(function(){
                    $main.dropDown( $(this), popupBox.find('.drop-down') );
                });

            }
        });
        return false;

    });
}

$mandatoryForm.showErrors = function(errors){
    $('#mandatory-search-form .form-error').removeClass('form-error');
    $('#mandatory-search-form .form-error-message').hide();
    for(var i in errors){
        $('#' + i).addClass('form-error');
        $('#' + i).parent().find('.form-error-message').html(errors[i]).fadeIn();
    }

};

$(document).ready(function(){
    $mandatoryForm.init();
});