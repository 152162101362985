'use strict';

function toggleActiveState( select ){

    if ( select.val() && select.val() != 0 ) {
        select.addClass('active');
        return;
    }

    select.removeClass('active');

}

// Select2 call
$('.select-2').each(function(i, elem){

    var select = $(elem);

    select.select2({
        minimumResultsForSearch: 10,
        placeholder: select.find('option:first').text()
    });

    toggleActiveState( select );

}).change(function(){ toggleActiveState( $(this) ); });
