'use strict';

function Popup(){

}

Popup.prototype.template = function( className ){

    return '<div class="popup">'+
                '<div class="table">'+
                    '<div class="table-cell popup-destroy">'+
                        '<div class="popup-container popup-destroy">'+
                            '<div class="popup-content pr ' + className + ' loading">'+
                                '<div class="popup-close popup-destroy sprite"></div>'+
                            '</div>'+
                        '</div>'+
                    '</div>'+
                '</div>'+
            '</div>';

};

Popup.prototype.destroy = function( slow, currentPopup ){

    var popup = currentPopup || $('.popup');

    if ( slow ) {

        popup.find('.popup-content').fadeOut('slow', function(){
            popup.remove();
            $('body').removeClass('hidden');
        });

        return;

    }

    $('body').removeClass('hidden');
    popup.remove();

};

Popup.prototype.initialize = function( config ){

    var getCurrentView = $popup.template( config.className );

    getCurrentView = $(getCurrentView);

    $('body').addClass('hidden').append( getCurrentView );

    getCurrentView.on('click', function(event){

        var $clicked = $(event.target);

        if ( $clicked.is('.popup-destroy') ) {

            $popup.destroy( config.destroyAnimation, getCurrentView );

        }

    });

    $(document).on('keydown', function(event){

        if ( event.keyCode == 27 ) {

            $popup.destroy( true, getCurrentView );

        }

    });

    getCurrentView.find('.popup-content').removeClass('loading')
        .prepend( config.html );

    config.complete( getCurrentView );

    //$.ajax({
    //    url      : config.htmlUrl,
    //    type     : 'get',
    //    dataType : 'html',
    //    success  : function( view ){
    //
    //        getCurrentView.find('.popup-content').removeClass('loading')
    //                      .prepend( view );
    //
    //        config.complete( getCurrentView );
    //
    //    },
    //    error: function(){
    //
    //        getCurrentView.find('.popup-content').removeClass('loading')
    //                      .prepend( '<div class="tc" style="color: #f3770a;">Error!</div>' );
    //
    //    }
    //});

};

var $popup = new Popup();