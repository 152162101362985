$payerMessage = {};

$payerMessage.initPhotoUploader = function(){
    $("#payer-photo-uploader").on('change', function() {
        var self = $(this);
        var data = new FormData();
        data.append('_token', $csrfToken);
        data.append(self.attr('name'), self[0].files[0]);
        $.ajax({
            data: data,
            url: self.data('action'),
            type: 'post',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            xhrFields: {
                withCredentials: true
            },
            success: function (response) {
                if (response.status == 'OK') {
                    $("#payer-photo").attr('src', response.data.file_path);
                    $("#payer-photo-val").val(response.data.file_name);
                } else {
                    alert(response.status + ' ' + response.statusText);
                }
                self.val('');
            },
            error: function(response){
                alert(response.status + ' ' + response.statusText);
            }
        });
    });

};

$payerMessage.saverForm = $("#payer-message-saver-form");

$payerMessage.isDisabled = false;
$payerMessage.initSaver = function(){

    $payerMessage.saverForm.submit(function(){
        if ($payerMessage.isDisabled) {
            return false;
        }
        $payerMessage.isDisabled = true;
        $(".submit-btn", $payerMessage.saverForm).attr('disabled', 'disabled');
        $.ajax({
            url: $payerMessage.saverForm.attr('action'),
            type: $payerMessage.saverForm.attr('method'),
            data:  $payerMessage.saverForm.serialize(),
            dataType: 'json',
            success: function(response){
                $payerMessage.isDisabled = false;
                $(".submit-btn", $payerMessage.saverForm).removeAttr('disabled');
                if(response.status == 'OK'){
                    $("#payer-message-block").remove();
                    $("#payer-message-done").show();
                } else if(response.status == 'INVALID_DATA'){
                    $payerMessage.showErrors(response.errors);
                } else {
                    alert(response.status + ' ' + response.statusText);
                }
            },
            error: function(response){
                alert(response.status + ' ' + response.statusText);
            }
        });
        return false;
    });
};

$payerMessage.showErrors = function(errors) {
    $(".form-error-message", $payerMessage.saverForm).text('').hide();
    for (var key in errors) {
        $("#form-error-message").text(errors[key]).show();
    }
};

$(document).ready(function(){
    $payerMessage.initPhotoUploader();
    $payerMessage.initSaver();
});
