var $csrfToken = '';
var $gForm = {};

$gForm.gLocked = false;
$gForm.formId = "form-data";
$gForm.errorsScope = null;

$gForm.onSuccess = function(response){
    if (response.data && typeof response.data.redirect_url != 'undefined') {
        window.location.href = response.data.redirect_url;
    }
};

$gForm.onError = function(response){
    this.showErrors(response.errors);
};

$gForm.preventSubmit = false;
$gForm.beforeSubmit = function(){};
$gForm.onComplete = function(){};

$gForm.putCsrfToken = function(){
    var self = this;
    if ($csrfToken == '') {
        $.ajax({
            type: 'GET',
            url: $apiUrl+'csrf-token',
            dataType: 'json',
            async: false,
            xhrFields: {
                withCredentials: true
            },
            success: function(response){
                if (response.status == "OK") {
                    $csrfToken = response.data;
                    $("#"+self.formId+" input[name='_token']").val($csrfToken);
                }
            }
        });
    } else {
        $("#"+self.formId+" input[name='_token']").val($csrfToken);
    }
};

$gForm.init = function(){
    var self = this;
    if (self.errorsScope == null) {
        self.errorsScope = $("#" + self.formId);
    }

    // self.putCsrfToken();

    $("#"+self.formId).off().on('submit', function(){

        self.beforeSubmit();
        if (self.preventSubmit) {
            return false;
        }

        if (self.gLocked) {
            return false;
        }
        self.gLocked = true;
        $(".submit-btn", $(this)).attr('disabled', 'disabled').addClass('loading');
        $("body").addClass("loading");

        var method = $("#"+self.formId).attr('method');
        method = (typeof method == 'undefined') ? 'post' : method;
        $.ajax({
            url: $("#"+self.formId).attr('action'),
            type: method,
            data: $("#"+self.formId).serialize(),
            dataType: 'json',
            xhrFields: {
                withCredentials: true
            },
            success: function(response){
                if (!response.data || typeof response.data.redirect_url == 'undefined') {
                    $(".submit-btn", $("#" + self.formId)).removeAttr('disabled').removeClass('loading');
                    $("body").removeClass("loading");
                }
                if(response.status == 'OK'){
                    self.showErrors([]);
                    self.onSuccess(response);
                } else if(response.status == 'INVALID_DATA'){
                    self.onError(response);
                } else {
                    self.showErrors([]);
                    self.processResponse(response);
                }
                self.gLocked = false;
            },
            error: function(response){
                $(".submit-btn", $("#"+self.formId)).removeAttr('disabled').removeClass('loading');
                self.gLocked = false;
                self.processResponse(response.responseJSON);
            },
            complete: function(){
                self.onComplete();
            }
        });
        return false;
    });

    $("#"+self.formId+" input").on("focus", self.cleanError);
    $("#"+self.formId+" select").on( "selectmenufocus", self.cleanError);
    $("#"+self.formId+" textarea").on( "focus", self.cleanError);
};

$gForm.cleanError =  function(){
    $(this).closest(".form-error").removeClass("form-error");
    $(".form-error-message", $(this).closest(".form__box, .form-input-box")).hide().html('');
};

$gForm.showErrors = function(errors){
    $(".form-error-message", this.errorsScope).hide().html('');
    $(".form__box, .form-input-box", this.errorsScope).removeClass('form-error');
    for(var i in errors){
        $(".form-error-" + i, this.errorsScope).html(errors[i]).fadeIn();
        $(".form-error-" + i, this.errorsScope).closest('.form__box, .form-input-box').find('input, textarea').addClass('form-error');
    }

    if ($(".form-error").length > 0) {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".form-error").first().offset().top
        }, 1000);
    }
};

$gForm.processResponse = function(response){
    var msg = 'An error occurred.';
    if (typeof response.message != 'undefined') {
        msg = response.message;
    } else if(typeof response.data.message != 'undefined') {
        msg = response.data.message;
    }
    $modal.show(msg, '', 'info');
};